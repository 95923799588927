import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8e275e38"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "uni-video"
};
var _hoisted_2 = ["id", "muted", "poster"];
var _hoisted_3 = ["src"];
import { nextTick, onMounted, ref } from "vue";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-hls";
export default {
  __name: 'index',
  props: {
    poster: {
      type: String,
      default: ""
    },
    videoUrl: {
      type: String,
      default: ""
    },
    elementId: {
      type: String,
      default: "my-video"
    },
    autoPlay: {
      type: Boolean,
      default: true
    },
    isShowMask: {
      type: Boolean,
      default: false
    },
    muted: {
      type: Boolean,
      default: true
    },
    controls: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: "auto"
    },
    maskHeight: {
      type: String,
      default: "980px"
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    var props = __props;
    var myVideo = ref(null);
    var showMask = ref(false);
    onMounted(function () {
      initVideo();
    });
    var initVideo = function initVideo() {
      nextTick(function () {
        myVideo.value = videojs(props.elementId, {
          bigPlayButton: false,
          textTrackDisplay: false,
          posterImage: false,
          errorDisplay: false,
          controlBar: true,
          restoreEl: true
        });
        var videoRef = document.querySelector("#".concat(props.elementId, "_html5_api"));
        videoRef.style.height = props.height;
        if (props.autoPlay) {
          play();
        }
      });
    };
    var showMasks = function showMasks() {
      showMask.value = true;
    };
    var closeMasks = function closeMasks() {
      showMask.value = false;
    };
    var pause = function pause() {
      var _myVideo$value;
      (_myVideo$value = myVideo.value) === null || _myVideo$value === void 0 ? void 0 : _myVideo$value.pause();
    };
    var play = function play() {
      var _myVideo$value2;
      (_myVideo$value2 = myVideo.value) === null || _myVideo$value2 === void 0 ? void 0 : _myVideo$value2.play();
    };
    var replay = function replay() {
      var _myVideo$value3;
      (_myVideo$value3 = myVideo.value) === null || _myVideo$value3 === void 0 ? void 0 : _myVideo$value3.currentTime(0);
      play();
    };
    var dispose = function dispose() {
      var _myVideo$value4;
      (_myVideo$value4 = myVideo.value) === null || _myVideo$value4 === void 0 ? void 0 : _myVideo$value4.dispose();
    };
    expose({
      dispose: dispose,
      play: play,
      pause: pause,
      replay: replay,
      myVideo: myVideo,
      showMasks: showMasks,
      closeMasks: closeMasks
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("video", {
        class: "my-video",
        id: __props.elementId,
        muted: __props.muted,
        preload: "auto",
        loop: "",
        poster: __props.poster,
        width: "100%",
        height: "100%",
        "object-fit": "fill"
      }, [_createTextVNode(_toDisplayString(_ctx.$t("home.notSupportedVideo")) + " ", 1), _createElementVNode("source", {
        src: __props.videoUrl,
        type: "application/x-mpegURL"
      }, null, 8, _hoisted_3)], 8, _hoisted_2), _unref(showMask) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "video-mask",
        style: _normalizeStyle({
          height: __props.maskHeight
        })
      }, null, 4)) : _createCommentVNode("", true)]);
    };
  }
};