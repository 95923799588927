import request from "@/utils/request";
export default class ApiOutApi {
  /**
   * @description 获取设计师列表
   * */
  static getDesignerList(params) {
    return request({
      url: "api/v2/designer/list",
      method: "GET",
      // urlType: "outapi",
      params,
    });
  }
  /**
   * @description 关注设计师
   * */
  static focusDesigners(data) {
    return request({
      url: "api/v2/concern/concernOrCancel",
      method: "POST",
      isTranslate: false,
      data,
    });
  }
  /**
   * @description 获取设计师作品列表
   * */
  static getWorkerDesignerList(params) {
    return request({
      url: "api/v2/designer/worker/list",
      method: "GET",
      // urlType: "outapi",
      params,
    });
  }

  /**
   * @description 收藏/取消收藏设计师作品
   * @param data { object }
   * */
  static collectDesigner(data) {
    return request({
      url: "api/v2/concern/collect",
      method: "POST",
      data,
    });
  }
}
