import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-44a58b2f"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "global-designer"
};
import { ref } from "vue";
import { useRoute, onBeforeRouteUpdate } from "vue-router";
import WorkWallBanner from "@/views/global_designer/global-designer-module/work-wall-banner.vue";
import GlobalDesignerNavBar from "@/views/global_designer/global-designer-module/global-designer-nav-bar.vue";
import WorkWallItem from "@/views/global_designer/global-designer-module/work-wall-item.vue";
import GlobalDesigners from "@/views/global_designer/global-designer-module/global-designers.vue";
export default {
  __name: 'index',
  setup: function setup(__props) {
    var route = useRoute();
    var pageType = route.query.type;
    onBeforeRouteUpdate(function (to) {
      currentType.value = to.query.type;
      pageType = to.query.type;
    });
    var currentType = ref("workWall");
    if (pageType) {
      currentType.value = pageType;
    }
    var workWallItemRef = ref(null);
    var globalDesignersRef = ref(null);
    var getSearchKeywords = function getSearchKeywords(val) {
      if (currentType.value === "workWall") {
        workWallItemRef === null || workWallItemRef === void 0 ? void 0 : workWallItemRef.value.getDesignerLists(val);
      } else {
        globalDesignersRef === null || globalDesignersRef === void 0 ? void 0 : globalDesignersRef.value.getDesignerLists(val);
      }
    };
    var changeGlobalDesignerType = function changeGlobalDesignerType(val) {
      currentType.value = val;
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(WorkWallBanner), _createVNode(GlobalDesignerNavBar, {
        currentType: currentType.value,
        onSearch: getSearchKeywords,
        onChangeGlobalDesignerType: changeGlobalDesignerType
      }, null, 8, ["currentType"]), _createVNode(_Transition, {
        name: "fade"
      }, {
        default: _withCtx(function () {
          return [currentType.value === 'workWall' ? (_openBlock(), _createBlock(WorkWallItem, {
            key: 0,
            ref_key: "workWallItemRef",
            ref: workWallItemRef,
            class: "WorkWallItem"
          }, null, 512)) : _createCommentVNode("", true)];
        }),
        _: 1
      }), _createVNode(_Transition, {
        name: "slide"
      }, {
        default: _withCtx(function () {
          return [currentType.value !== 'workWall' ? (_openBlock(), _createBlock(GlobalDesigners, {
            key: 0,
            ref_key: "globalDesignersRef",
            ref: globalDesignersRef,
            class: "GlobalDesigners"
          }, null, 512)) : _createCommentVNode("", true)];
        }),
        _: 1
      })]);
    };
  }
};