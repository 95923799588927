import { unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8999944c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "work-wall"
};
var _hoisted_2 = {
  class: "content"
};
var _hoisted_3 = {
  class: "main-title"
};
var _hoisted_4 = {
  class: "small-desc"
};
var _hoisted_5 = {
  class: "operation"
};
import { ref, onMounted, onBeforeUnmount, nextTick } from "vue";
import UniVideo from "@/uni-ui/uni-video/index.vue";
import HandlerModal from "@/views/global_designer/handler-modal/index.vue";
export default {
  __name: 'work-wall-banner',
  setup: function setup(__props) {
    var screenHeight = ref(0);
    var designVideo = ref(null);
    var isShowModal = ref(false);
    var handlerModalRef = ref(null);
    screenHeight.value = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 80;
    onMounted(function () {
      var _designVideo$value;
      window.onresize = function () {
        return function () {
          screenHeight.value = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 80;
        }();
      };
      (_designVideo$value = designVideo.value) === null || _designVideo$value === void 0 ? void 0 : _designVideo$value.showMasks();
    });
    var apply = function apply() {
      var token = localStorage.getItem("global_token");
      if (!token) {
        window.$message.warning(window.$t("GlobalDesigner.pleaseLogin"));
        window.$store.commit("SET_SHOW_PANEL", true);
        return;
      }
      isShowModal.value = true;
      nextTick(function () {
        var _handlerModalRef$valu;
        var options = {
          title: "申请入驻",
          type: 10
        };
        (_handlerModalRef$valu = handlerModalRef.value) === null || _handlerModalRef$valu === void 0 ? void 0 : _handlerModalRef$valu.open(options);
      });
    };
    var accessPlatform = function accessPlatform() {
      var token = localStorage.getItem("global_token");
      if (!token) {
        window.$message.warning(window.$t("GlobalDesigner.pleaseLogin"));
        window.$store.commit("SET_SHOW_PANEL", true);
        return;
      }
      isShowModal.value = true;
      nextTick(function () {
        var _handlerModalRef$valu2;
        var options = {
          title: window.$t("uniRecommend.warmReminder"),
          type: 20,
          content: window.$t("GlobalDesigner.accessPlatformTips")
        };
        (_handlerModalRef$valu2 = handlerModalRef.value) === null || _handlerModalRef$valu2 === void 0 ? void 0 : _handlerModalRef$valu2.open(options);
      });
    };
    onBeforeUnmount(function () {
      var _designVideo$value2;
      (_designVideo$value2 = designVideo.value) === null || _designVideo$value2 === void 0 ? void 0 : _designVideo$value2.dispose();
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "block-1",
        style: _normalizeStyle({
          height: _unref(screenHeight) + 'px'
        })
      }, [_createVNode(UniVideo, {
        ref_key: "designVideo",
        ref: designVideo,
        elementId: "design-video",
        poster: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/video/designer-poster.jpg",
        "video-url": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/video/a.m3u8"
      }, null, 512)], 4), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('GlobalDesigner.globalDesignerText')) + " " + _toDisplayString(_ctx.$t('GlobalDesigner.greationPlatform')), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('GlobalDesigner.possibilities')) + " " + _toDisplayString(_ctx.$t('GlobalDesigner.continuous')), 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
        class: "settle-in-btn flex-center effect-btn",
        onClick: apply
      }, _toDisplayString(_ctx.$t('GlobalDesigner.admission')), 1), _createElementVNode("div", {
        class: "into-btn flex-center effect-btn",
        onClick: accessPlatform
      }, _toDisplayString(_ctx.$t('GlobalDesigner.accessPlatform')), 1)])]), _unref(isShowModal) ? (_openBlock(), _createBlock(HandlerModal, {
        key: 0,
        ref_key: "handlerModalRef",
        ref: handlerModalRef
      }, null, 512)) : _createCommentVNode("", true)]);
    };
  }
};